/*===========================================
    l-groupHeader
===========================================*/
.l-groupHeader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px $color-border;
  background-color: $color-white;
  font-size: $font-size-sm;
  @include media-query(lg) {
    min-width: $layout-min-width + $layout-clearance-lg * 2;
    width: 100%;
    height: 48px;
    padding: 12px $layout-clearance-lg;
  }
  @include media-query(sm) {
    width: 100%;
    height: 32px;
    padding: 8px $layout-clearance-sm 8px;
  }

  a {
    color: $color-black;
  }

  .groupHeader__link {
    @include media-query(lg) {
      width: 160px;
    }
    @include media-query(sm) {
      width: 120px;
    }

    img {
      width: 100%;
    }
  }
}
