/*===========================================
    l-footer
===========================================*/
.l-footer {
  background-color: $color-white;
  @include media-query(lg) {
    min-width: $layout-min-width + $layout-clearance-lg * 2;
    width: 100%;
  }
  @include media-query(sm) {
    width: 100%;
  }

  .footer__inner {
    @include media-query(lg) {
      display: flex;
      justify-content: space-between;
      padding: $layout-secction-lg $layout-clearance-lg;
    }
    @include media-query(sm) {
      padding: $layout-secction-sm $layout-clearance-sm;
    }
  }

  .footer__information {

    .information__logo {
      display: block;
      @include media-query(lg) {
        width: 236px;
      }
      @include media-query(sm) {
        width: 40vw;
        margin: 0 auto;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .information__location {
      @include media-query(lg) {
        margin-top: 64px;
      }
      @include media-query(sm) {
        margin-top: 40px;
      }

      dl {
        display: flex;
        margin-top: 24px;

        dt {
          width: 6rem;
          font-weight: bold;
        }
      }
    }
  }

  .footer__navigation {
    display: flex;
    flex-direction: column;
    height: auto;
    @include media-query(lg) {
      align-items: flex-end;
      justify-content: space-between;
    }
    @include media-query(sm) {
      align-items: center;
    }

    .navigation__list {
      display: flex;
      align-items: center;
      @include media-query(sm) {
        display: none;
      }

      li {
        margin-right: 32px;

        &:last-child {
          margin-right: 0;
        }

        a {
          color: $color-black;
          font-weight: bold;
        }
      }
    }

    .navigation__midoriya {
      display: block;
      @include media-query(lg) {
        width: 236px;
        height: 48px;
        margin-top: auto;
      }
      @include media-query(sm) {
        width: 40vw;
        margin-top: 80px;
      }

      img {
        width: 100%;
      }
    }
  }

  .footer__bottom {
    border-top: solid 1px $color-border;
    font-size: $font-size-sm;
    @include media-query(lg) {
      display: flex;
      justify-content: space-between;
      padding: 16px $layout-clearance-lg;
    }
    @include media-query(sm) {
      display: block;
      padding: 16px $layout-clearance-sm;
      text-align: center;
    }

    a {
      color: $color-black;
    }

    .footer__privacyPolicy {
      @include media-query(sm) {
        display: none;
      }
    }
  }
}
