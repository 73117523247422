/*===========================================
    c-heading
===========================================*/

$heding-size-mag: 1.2;

//-------------------------------------------

[class*="c-heading"] {
  position: relative;
  color: $color-text;
  font-family: $font-family-secondary;
  font-weight: 300;
  line-height: 1.4;
  letter-spacing: 0.1em;


  //-----------------------------------------
  //  size
  //-----------------------------------------
  @mixin heading-size($fontSize: $font-size-md) {
    font-size: $fontSize * $heding-size-mag;
  }

  &[class*="-xl"] {
    @include heading-size($font-size-xl);
  }

  &[class*="-lg"] {
    @include heading-size($font-size-lg);
  }

  &[class*="-md"] {
    @include heading-size($font-size-md);
  }

  &[class*="-sm"] {
    @include heading-size($font-size-sm);
  }

  &[class*="-xs"] {
    @include heading-size($font-size-xs);
  }


  //-----------------------------------------
  //  color
  //-----------------------------------------
  &[class*="-black"] {
    color: $color-black;
  }

  &[class*="-white"] {
    color: $color-white;
  }

  &[class*="-primary"] {
    color: $color-primary;
  }

  &[class*="-secondary"] {
    color: $color-secondary;
  }


  //-----------------------------------------
  //  type
  //-----------------------------------------
  &[class*="-left"] {
    text-align: left;
  }

  &[class*="-center"] {
    text-align: center;
  }

  &[class*="-right"] {
    text-align: right;
  }

  &[class*="-line"] {
    text-decoration: underline;
  }


  //-----------------------------------------
  //  additional
  //-----------------------------------------
  [class*="heading__sub"] {
    color: $color-text;
    font-size: 0.5em;
    font-family: $font-family-secondary;
    vertical-align: middle;

    &[class*="-top"] {
      display: block;
      margin-bottom: 0.5em;
    }

    &[class*="-bottom"] {
      display: block;
      margin-top: 0.5em;
    }

    &[class*="-before"] {
      display: inline-block;
      margin-right: 1em;
    }

    &[class*="-after"] {
      display: inline-block;
      margin-left: 1em;
    }
  }

  [class*="heading__icon"] {
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: middle;

    img {
      display: block;
      width: auto;
      height: 100%;
    }

    &[class*="--before"] {
      margin-right: 0.5em;
    }

    &[class*="--after"] {
      margin-left: 0.5em;
    }
  }

  [class*="heading__line"] {
    display: inline-flex;
    align-items: center;
    margin-top: -0.1em;
    font-size: 1.4em;

    &:after {
      content: "";
      display: block;
      height: 1px;
      @include media-query(lg) {
        width: 3em;
        margin: 0 1em;
      }
      @include media-query(sm) {
        width: 2em;
        margin: 0 0.5em;
      }
    }

    &[class*="-black"] {
      &:after {
        background-color: $color-black;
      }
    }

    &[class*="-white"] {
      &:after {
        background-color: $color-white;
      }
    }
  }
}
