//===========================================
//  section-size
//===========================================
@mixin sectionSize-lg {
  max-width: $layout-max-width;
  min-width: $layout-min-width;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@mixin sectionSize-sm {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
