/*===========================================
    c-card
===========================================*/

$card-padding-mag: 1.5;
$card-spacing-mag: 0.5;

$card-border-type:  solid;
$card-border-width: 1px;
$card-border-color: $color-border;

//-------------------------------------------

[class*="c-card"] {
  background-color: $color-white;

  .card__image {
    position: relative;
    overflow: hidden;
    width: 100%;

    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-top: 61.8%;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .card__head {
    border-bottom: $card-border-type $card-border-width $card-border-color;
  }

  .card__body {

    & > * {

      &:first-child {
        margin-top: 0 !important;
      }
    }
  }

  .card__foot {
    border-top: $card-border-type $card-border-width $card-border-color;
  }

  .card__caption {
    font-size: 2em;
  }

  .card__credit {
    color: lighten($color-text, 60%);
    font-size: 0.75em;
    font-style: italic;
  }


  //-----------------------------------------
  //  size
  //-----------------------------------------
  @mixin card-size($fontSize: $font-size-md) {
    font-size: $fontSize;

    .card__body {
      padding: $fontSize * $card-padding-mag;

      & > * {
        margin-top: $fontSize * $card-spacing-mag;
      }
    }

    .card__head,
    .card__foot {
      padding: calc($fontSize * $card-padding-mag / 2) calc($fontSize * $card-padding-mag);
    }
  }

  &[class*="-xl"] {
    @include card-size($font-size-xl);
  }

  &[class*="-lg"] {
    @include card-size($font-size-lg);
  }

  &[class*="-md"] {
    @include card-size($font-size-md);
  }

  &[class*="-sm"] {
    @include card-size($font-size-sm);
  }

  &[class*="-xs"] {
    @include card-size($font-size-xs);
  }


  //-----------------------------------------
  //  option
  //-----------------------------------------
  &[class*="-shadow"] {
    box-shadow: 1em 1em 1em $color-shadow;
  }
}
