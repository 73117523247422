//===========================================
//  layout
//===========================================

//-------------------------------------------
//  ページ
//-------------------------------------------
$layout-max-width: 1440px;
$layout-min-width: 1120px;
$layout-clearance-lg: 76px;
$layout-clearance-sm: 16px;

//-------------------------------------------
//  セクション
//-------------------------------------------
$layout-secction-lg: 96px;
$layout-secction-sm: 80px;
