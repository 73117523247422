/*===========================================
    c-text
===========================================*/

[class*="u-text"] {

  //-----------------------------------------
  //  type
  //-----------------------------------------
  &[class*="-bold"] {
    font-weight: bold !important;
  }

  &[class*="-italic"] {
    font-style: italic !important;
  }

  &[class*="-underline"] {
    text-decoration: underline !important;
  }

  &[class*="-highlight"] {
    background-color: $color-mark !important;
  }


  //-----------------------------------------
  //  size
  //-----------------------------------------
  &[class*="-xl"] {
    font-size: $font-size-xl !important;
  }

  &[class*="-lg"] {
    font-size: $font-size-lg !important;
  }

  &[class*="-md"] {
    font-size: $font-size-md !important;
  }

  &[class*="-sm"] {
    font-size: $font-size-sm !important;
  }

  &[class*="-xs"] {
    font-size: $font-size-xs !important;
  }
}
