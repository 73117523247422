/*===========================================
    js-contentShow
===========================================*/
.js-contentShow {
}

.js-contentShow__trigger {
}

.js-contentShow__content {
  // @include transition;
}

.js-contentShow__item {
  @include transition(1s);
  display: none;
  opacity: 0;

  &.is-active {
    opacity: 1;
  }
}
