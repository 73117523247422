/*===========================================
    js-parallaxImage
===========================================*/
.js-parallaxImage {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;

  img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    // object-fit: cover;
    @include media-query(lg) {
      width: 100%;
      height: auto;
    }
    @include media-query(sm) {
      width: auto;
      height: 120%;
    }
  }
}
