/*===========================================
    c-list
===========================================*/

//-------------------------------------------

[class*="c-list"] {

  li {
    position: relative;
    list-style-position: inside;
  }

  & > li {

    & + li {
      margin-top: 1em;
    }

    & > ul,
    & > ol {
      margin-top: 1em;
      // margin-left: 1em;
    }

    .list__block {
      margin-top: 1em;
      margin-left: 1em;
    }

    .list__icon {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }


  //-----------------------------------------
  //  type
  //-----------------------------------------
  &[class*="-disc"] {

    & > li {
      padding-left: 1em;
      text-indent: -1em;
      list-style-type: disc;
    }
  }

  &[class*="-number"] {

    & > li {
      padding-left: 1.8em;
      text-indent: -1.8em;
      // list-style-type: decimal;
      list-style-type: decimal-leading-zero;
    }
  }

  &[class*="-icon"] {

    & > li {
      padding-left: 1.4em;
      text-indent: -1.4em;

      i, img {
        display: inline-block;
        width: 1em;
        height: 1em;
        margin-right: 0.5em;
        font-size: 1em;
        vertical-align: baseline;
      }
    }
  }

  &[class*="-bkimg"] {

    & > li {
      padding-left: 1.4em;
      text-indent: -1.4em;

      &:before {
        content: '';
        display: inline-block;
        width: 1em;
        height: 1em;
        margin-right: 0.5em;
        background-image: url('http://placehold.it/64x64');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }

  &[class*="-link"] {

    & > li {


      a {
        position: relative;
        display: block;
        padding-right: 3em;

        i, img {
          position: absolute;
          top: 50%;
          right: 0;
          display: inline-block;
          width: 2em;
          height: 2em;
          margin-top: -1em;
          font-size: 1em;
        }
      }
    }
  }

  //-----------------------------------------
  //  option
  //-----------------------------------------
  &[class*="-line"] {

    & > li {
      margin-top: 0;
      border-bottom: solid 1px $color-border;

      &:first-child {
        border-top: solid 1px $color-border;
      }

      & > a {
        padding-top: 1em;
        padding-bottom: 1em;
      }
    }
  }
}
