/*===========================================
    c-link
===========================================*/

$button-border-width: 2px;
$button-border-radius: 0;

//-------------------------------------------

[class*="c-link"] {
  @include transition;
  color: $color-link;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.1em;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    opacity: $hover-opacity;
  }

  &.is-active {
  }

  &.is-disabled {
    opacity: $hover-opacity;
    pointer-events: none;
  }


  //-----------------------------------------
  //  type
  //-----------------------------------------
  &[class*="-icon"] {

    i, img {
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-right: 0.5em;
      vertical-align: middle;
    }
  }

  &[class*="-bkimg"] {

    &:before {
      content: '';
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-right: 0.5em;
      background-image: url('../images/icon/icon_link.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}
