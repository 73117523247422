//===========================================
//  font
//===========================================

//-------------------------------------------
//  フォントファミリー
//-------------------------------------------
$font-family-primary:   "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
$font-family-secondary: "Roboto", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
$font-family-number:    "Roboto", sans-serif;


//-------------------------------------------
//  フォントサイズ
//-------------------------------------------
$font-default-lg:       14px;
$font-default-md:       14px;
$font-default-sm:       14px;

$font-size-xl:          1.25rem;
$font-size-lg:          1.125rem;
$font-size-md:          1rem;
$font-size-sm:          0.875rem;
$font-size-xs:          0.75rem;
