/*===========================================
    l-header
===========================================*/
.l-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  background-color: $color-white;
  box-shadow: 0 16px 16px $color-shadow;
  @include media-query(lg) {
    min-width: $layout-min-width + $layout-clearance-lg * 2;
    width: 100%;
    padding: 72px $layout-clearance-lg 24px;
  }
  @include media-query(sm) {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 48px $layout-clearance-sm 16px;
  }

  .header__logo {
    @include media-query(lg) {
      width: 236px;
      margin-right: 64px;
    }
    @include media-query(sm) {
      height: 24px;
    }

    img {
      @include media-query(lg) {
        width: 100%;
      }
      @include media-query(sm) {
        width: auto;
        height: 100%;
      }
    }
  }

  .header__navigation {

    .navigation__menu {
      @include media-query(lg) {
        display: none;
      }
      @include media-query(sm) {
        font-size: $font-size-xl;
      }
    }

    .navigation__content {
      @include media-query(sm) {
        padding: $layout-clearance-sm * 2;
        background-color: rgba($color-primary, 0.8);
      }
    }

    .navigation__list {
      @include media-query(lg) {
        display: flex;
        align-items: center;
      }

      li {
        @include media-query(lg) {
          margin-left: 32px;
        }
        @include media-query(sm) {
          border-bottom: dotted 1px rgba($color-white, 0.6);
        }

        &:first-child {
          @include media-query(lg) {
            margin-right: 0;
          }
          @include media-query(sm) {
            margin-top: 0;
          }
        }

        a {
          position: relative;
          display: block;
          color: lighten($color-black, 50%);
          font-weight: bold;
          @include media-query(lg) {
            padding: 4px 0;
          }
          @include media-query(sm) {
            padding: 16px 0;
            color: $color-white;
            font-size: $font-size-lg;
          }

          &:before {
            @include transition;
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 0;
            height: 2px;
            @include media-query(lg) {
              background-color: $color-primary;
            }
            @include media-query(sm) {
              background-color: $color-white;
            }
          }

          &.is-active,
          &:hover {
            text-decoration: none;

            &:before {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
