/*===========================================
    js-menu
===========================================*/
.js-menu {

}

.js-menu__trigger {

}

.js-menu__content {
  @include media-query(sm) {
    @include transition;
    position: absolute;
    top: 99px;
    left: 0;
    // display: none;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    opacity: 0;
    pointer-events: none;
  }

  > * {
    @include transition;
    transform: translateY(-20%);
  }

  &.is-active {
    @include media-query(sm) {
      // display: block;
      opacity: 1;
      pointer-events: all;
    }

    > * {
      transform: translateY(0);
    }
  }
}
