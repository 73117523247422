/*===========================================
    p-top
===========================================*/
#p-top {

  //-----------------------------------------
  //  p-mainVisual
  //-----------------------------------------
  .p-mainVisual {
    position: relative;
    max-height: 800px;
    height: 100vh;
    @include media-query(lg) {
      min-width: $layout-min-width + $layout-clearance-lg * 2;
      width: 100vw;
    }
    @include media-query(sm) {
      width: 100vw;
    }

    .mainVisual__image {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .mainVisual__copy {
      position: absolute;
      @include media-query(lg) {
        top: calc(4vh + 120px);
        right: $layout-clearance-lg;
        width: 120px;
      }
      @include media-query(sm) {
        top: calc(4vh + 100px);
        right: $layout-clearance-sm;
        width: 20vw;
      }

      img {
        display: block;
        width: 100%;
      }
    }

    .mainVisual__news {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $color-primary;
      color: $color-white;
      @include media-query(lg) {
        display: flex;
        padding: 16px 16px 16px $layout-clearance-lg;
      }
      @include media-query(sm) {
        width: 100%;
        padding: $layout-clearance-sm;
      }

      .news__heading {
        @include media-query(lg) {
          margin-right: 24px;
        }
      }

      .news__link {
        display: block;
        color: $color-white;
        @include media-query(sm) {
          margin-top: 8px;
        }

        article {
          display: flex;
          align-items: center;

          time {
            margin-right: 1em;
          }

          i {
            margin-left: 1em;
          }
        }
      }
    }
  }

  //-----------------------------------------
  //  p-service
  //-----------------------------------------
  .p-service {
    padding: $layout-secction-lg 0;

    .service__inner {
      @include media-query(lg) {
        @include sectionSize-lg;
        padding: 0 $layout-clearance-lg;
      }
      @include media-query(sm) {
        @include sectionSize-sm;
        padding: 0 $layout-clearance-sm;
      }
    }

    .service__content {
      margin-top: 40px;

      .content__item {
        position: relative;
        overflow: hidden;
        color: $color-white;
        @include media-query(lg) {
          padding: 80px 32px 32px;
        }
        @include media-query(sm) {
          width: 100%;
          padding: 64px 16px 16px;
        }

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          background-color: rgba($color-secondary, 0.5);
        }

        .item__image {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          display: block;
          width: 100%;
          height: 100%;
        }

        .item__number,
        .item__title {
          position: relative;
          z-index: 2;
        }

        .item__title {
          margin-top: 0.4em;
        }
      }
    }

    .service__manufacturer {
      background-image: url('../images/background/bg_grid_01.png');
      background-position: top left;
      @include media-query(lg) {
        margin-top: -$layout-secction-lg;
        padding: $layout-secction-lg * 2 0;
      }
      @include media-query(sm) {
        margin-top: -$layout-secction-sm;
        padding: $layout-secction-sm * 2 0;
      }

      .manufacturer__inner {
        @include media-query(lg) {
          @include sectionSize-lg;
          padding: 0 $layout-clearance-lg;
        }
        @include media-query(sm) {
          @include sectionSize-sm;
          padding: 0 $layout-clearance-sm;
        }
      }

      .manufacturer__title {
      }

      .manufacturer__list {
        position: relative;
        margin-top: 40px;
      }

      .manufacturer__card {

        .card__body {
          @include media-query(lg) {
            padding: 2rem;
          }
          @include media-query(sm) {
            padding: 1.5rem;
          }

          .body__image {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            // @include media-query(lg) {
            //   height: 120px;
            // }
            // @include media-query(sm) {
            //   height: 80px;
            // }

            img {
              width: 100%;
              height: auto;
            }
          }

          h4 {
            margin-top: 2rem;
            font-weight: bold;
          }
        }
      }

      .manufacturer__other {
        @include media-query(lg) {
          width: calc((100% - 1.5rem * 1) / 2) !important;
        }
        @include media-query(sm) {
          width: 100% !important;
        }

        .other__inner {
          display: flex;
          flex-direction: column;
          align-items: left;
          justify-content: center;
          width: 100%;
          height: 100%;
          background-color: #fff;
          @include media-query(lg) {
            padding: 4rem;
          }
          @include media-query(sm) {
            padding: 2rem;
          }

          p {
            margin-top: 2rem;
          }
        }
      }
    }

    .manufacturer__button {
      margin-top: 80px;
      text-align: center;
    }

    .service__engineering {
      position: relative;
      @include media-query(lg) {
        margin-top: -$layout-secction-lg;
      }

      .engineering__image {
        overflow: hidden;
        @include media-query(lg) {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 0;
          width: 50%;
          height: 800px;
        }
        @include media-query(sm) {
          position: relative;
          width: 100%;
          height: 280px;
        }

        img {
          // position: absolute;
          // top: 50%;
          // left: 50%;
          // transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .engineering__inner {
        position: relative;
        z-index: 1;
        @include media-query(lg) {
          @include sectionSize-lg;
          padding: $layout-secction-lg * 2 $layout-clearance-lg 0;
        }
        @include media-query(sm) {
          @include sectionSize-sm;
          padding: $layout-secction-sm * 2 $layout-clearance-sm 0;
        }
      }

      .engineering__summary {
        @include media-query(lg) {
          width: 50%;
          padding-right: 120px;
          margin-top: 40px;
        }
        @include media-query(sm) {
          width: 100%;
          margin-top: 40px;
        }

        p {
          margin-top: 2em;
          line-height: 2;
        }
      }

      .engineering__detail {
        margin-top: 80px;
        background-color: #dee9ef;
        @include media-query(lg) {
          @include sectionSize-lg;
          padding: 64px $layout-secction-lg;
        }
        @include media-query(sm) {
          @include sectionSize-sm;
          padding: 32px 16px;
        }

        p {
          margin-top: 40px;
        }

        img {
          display: block;
          width: 100%;
          margin: 40px auto 0;
        }
      }
    }
  }


  //-----------------------------------------
  //  p-message
  //-----------------------------------------
  .p-message {
    background-image: url('../images/background/bg_grid_01.png');
    background-position: top left;
    @include media-query(lg) {
      padding: $layout-secction-lg 0;
    }
    @include media-query(sm) {
      padding: $layout-secction-sm 0;
    }

    .message__inner {
      @include media-query(lg) {
        @include sectionSize-lg;
        padding: 0 $layout-clearance-lg;
      }
      @include media-query(sm) {
        @include sectionSize-sm;
        padding: 0 $layout-clearance-sm;
      }
    }

    .message__content {

      margin: 40px auto 0;

      @include media-query(lg) {
        width: calc(100% - #{$layout-clearance-lg} * 4);
        padding: 80px;
      }
      @include media-query(sm) {
        width: 100%;
        padding: $layout-clearance-sm * 2;
      }

      p {
        margin-top: 40px;
        line-height: 2;

        &:first-child {
          margin-top: 0;
        }
      }

      .content__author {
        margin-top: 40px;
        text-align: right;

        span {
          margin-left: 1em;
          font-size: 1.2em;
          vertical-align: baseline;
        }
      }
    }
  }


  //-----------------------------------------
  //  p-philosophy
  //-----------------------------------------
  .p-philosophy {
    position: relative;
    background-image: url('../images/background/bg_grid_01.png');
    background-position: top left;
    @include media-query(lg) {
      padding: $layout-secction-lg 0;
    }
    @include media-query(sm) {
      padding: $layout-secction-sm 0;
    }

    .philosophy__image {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 400px;
    }

    .philosophy__inner {
      position: relative;
      z-index: 1;
      @include media-query(lg) {
        @include sectionSize-lg;
        padding: 0 $layout-clearance-lg;
      }
      @include media-query(sm) {
        @include sectionSize-sm;
        padding: 0 $layout-clearance-sm;
      }
    }

    .philosophy__list {
      margin-top: 40px;
    }

    .philosophy__card {

      .card__head {
        position: relative;
        padding: 2rem 0;
        border: none;
        background-color: $color-primary;
        color: $color-white;
        font-family: $font-family-secondary;
        font-weight: 300;
        font-size: 2rem;
        text-align: center;
        letter-spacing: 0.1em;
        line-height: 1.4;

        span {
          display: block;
          font-size: 0.5em;
          text-align: center;
        }

        &:before,
        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 2px;
          height: 16px;
        }

        &:before {
          bottom: 0;
          left: 50%;
          background-color: $color-white;
        }

        &:after {
          top: 100%;
          left: 50%;
          background-color: $color-primary;
        }
      }

      .card__body {
        padding: 2.6rem 2rem 2rem;

        h4 {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 3em;
          line-height: 1.6;
          text-align: center;
        }

        p {
          margin-top: 1.6rem;
        }
      }
    }
  }


  //-----------------------------------------
  //  p-company
  //-----------------------------------------
  .p-company {
    @include media-query(lg) {
      padding: $layout-secction-lg 0;
    }
    @include media-query(sm) {
      padding: $layout-secction-sm 0;
    }

    .company__inner {
      @include media-query(lg) {
        @include sectionSize-lg;
        padding: 0 $layout-clearance-lg;
      }
      @include media-query(sm) {
        @include sectionSize-sm;
        padding: 0 $layout-clearance-sm;
      }
    }

    .company__information {
      margin-top: 40px;

      tr {
        @include media-query(sm) {
          display: block;
          padding: 12px 0;
        }
      }

      th, td {
        @include media-query(sm) {
          display: block;
        }
      }
    }

    .company__access {
      margin-top: 40px;

      .access__location {
        margin-top: 40px;
        @include media-query(lg) {
          display: flex;
          justify-content: space-between;
        }

        &:first-child {
          margin-top: 0;
        }

        .location__detail {
          @include media-query(lg) {
            width: 20rem;
            margin-right: 4rem;
          }
          @include media-query(sm) {
            width: 100%;
          }

          dt {
            font-weight: bold;
          }

          dd {

            p {
              margin-top: 1rem;
            }
          }
        }

        .location__map {
          background-color: #f1f1f1;
          overflow: hidden;
          @include media-query(lg) {
            width: calc(100% - 24rem);
            height: 320px;
          }
          @include media-query(sm) {
            width: 100%;
            height: 320px;
            margin-top: 2rem;
          }

          iframe {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }


  //-----------------------------------------
  //  p-news
  //-----------------------------------------
  .p-news {
    background-image: url('../images/background/bg_grid_01.png');
    background-position: top left;
    @include media-query(lg) {
      padding: $layout-secction-lg 0;
    }
    @include media-query(sm) {
      padding: $layout-secction-sm 0;
    }

    .news__inner {
      @include media-query(lg) {
        @include sectionSize-lg;
        padding: 0 $layout-clearance-lg;
      }
      @include media-query(sm) {
        @include sectionSize-sm;
        padding: 0 $layout-clearance-sm;
      }
    }

    .news__content {
      margin: 40px auto 0;
      overflow-y: scroll;
      @include media-query(lg) {
        width: calc(100% - #{$layout-clearance-lg} * 4);
        padding: 80px;
        height: 640px;
      }
      @include media-query(sm) {
        width: 100%;
        padding: $layout-clearance-sm * 2;
        height: 50vh;
      }
    }

    .news__article {
      padding-bottom: 1.4rem;
      border-bottom: solid 1px $color-border;
      @include media-query(lg) {
        display: flex;
      }

      .article__time {
        @include media-query(lg) {
          width: 8em;
        }
        @include media-query(sm) {
          width: 100%;
        }
      }

      .article__content {
        @include media-query(lg) {
          width: calc(100% - 8em);
        }
        @include media-query(sm) {
          width: 100%;
          margin-top: 8px;
        }

        p {
          margin-top: 1em;

          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }


  //-----------------------------------------
  //  p-contact
  //-----------------------------------------
  .p-contact {
    position: relative;
    @include media-query(lg) {
      padding: $layout-secction-lg 0;
    }
    @include media-query(sm) {
      padding: $layout-secction-sm 0;
    }

    .contact__image {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
    }

    .contact__inner {
      @include media-query(lg) {
        @include sectionSize-lg;
        padding: 0 $layout-clearance-lg;
      }
      @include media-query(sm) {
        @include sectionSize-sm;
        padding: 0 $layout-clearance-sm;
      }
    }

    .contact__content {
      width: 100%;
      margin: 0 auto;
      @include media-query(lg) {
        display: flex;
      }

      > * {
        margin-top: 0;
      }

      .content__left {
        @include media-query(lg) {
          width: 50%;
          padding-right: 2.5rem;
          border-right: solid 1px $color-border;
        }
        @include media-query(sm) {
          width: 100%;
          padding-bottom: 2.5rem;
          border-bottom: solid 1px $color-border;
        }

        h2 {
          @include media-query(sm) {
            font-size: 0.9rem;
          }
        }

        p {
          margin-top: 40px;
        }
      }

      .content__right {
        @include media-query(lg) {
          width: 50%;
          padding-left: 2.5rem;
        }
        @include media-query(sm) {
          width: 100%;
          padding-top: 2.5rem;
        }
      }

      .content__tel {
        font-family: $font-family-secondary;
        font-weight: 400;
        line-height: 1.2;
        letter-spacing: 0.1em;
        text-align: center;
        vertical-align: middle;
        @include media-query(lg) {
          font-size: 2.4rem;
        }
        @include media-query(sm) {
          font-size: 2.0rem;
        }

        img {
          display: inline-block;
          width: 40px;
          height: 40px;
          margin-right: 0.4em;
        }
      }

      .content__hour {
        margin-top: 1em;
        text-align: center;
      }

      .content__button {
        margin-top: 1em;
      }
    }
  }
}
